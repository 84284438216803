


.signin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #1f242d;
  min-height: 100vh;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}


.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modalLoading{
 /* Inițial, fereastra modală este ascunsă */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Un fundal semi-transparent pentru a acoperi conținutul din spate */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center; 
}