@import 'bootstrap/dist/css/bootstrap.min.css';

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

  /* Stiluri pentru tema light */
.light-theme {
    background-color: #f7f7f7; /* culoarea de fundal pentru tema light */
    color: #212529; /* culoarea textului pentru tema light */
  }
  
  
  /* Stiluri pentru tema dark */
  .dark-theme {
    background-color: #212529; /* culoarea de fundal pentru tema dark */
    color: #ffffff; /* culoarea textului pentru tema dark */
  }
 /* Stilurile implicite Bootstrap 5 pentru butoanele de paginare */
.dark-theme-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;

}

.dark-theme-pagination .page-item:not(:first-child):not(:last-child) .page-link {
  border-radius: 0;
}

.dark-theme-pagination .page-item .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007BFF;
  background-color: #212529;

 
}

.dark-theme-pagination .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #212529;
  border-color: #fff;
  
}

.dark-theme-pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007BFF;
  border-color: #007BFF;
}
