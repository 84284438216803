/* CSS */
.bg-dark-collapsed {
    width: 0; /* Lățimea redusă a sidebar-ului în modul închis */
    /* Alte stilizări pentru modul închis */
  }


    .hide-sidebar {
      width: 0; /* Schimbă lățimea la 0 pentru a ascunde bara laterală */
      display: none;
      transition: all 0.3s ease; /* Poate adăuga o tranziție netedă la ascundere */
    }
  
  