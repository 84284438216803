
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--main-color); /* Culoarea de fundal pentru butonul de încărcare */
  color: var(--bg-color); /* Culoarea textului */
 cursor: pointer;
  border: none;
  border-radius: 5px;
}

.custom-file-upload input {
  display: none; /* Ascunde input-ul real de încărcare de fișiere */
}

.name-file{
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}




.progress-bar-containers {
    width: 100%;
    height: 20px;
    background-color: black; 
    border-radius: 4px; 
    overflow: hidden; 
  }
  

  .progress-bars {
    width: 0;
    height: 100%;
    background-color:whitesmoke; 
    transition: width 3s; 
  }
  
  
  .success-messages {
    color: white;
    font-weight: bold;
    margin-top: 10px; 
  }
  
  
  .progress-modals {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1); 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-contents {
    background-color: blue;
    padding: 20px;
    width: 300px;
    border-radius: 4px;
    text-align: center;
  }

  .modal-contents p {
    color: white;
  }
  